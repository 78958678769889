import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Icon from "@components/Icon";

const caption = "Build with us!";
const description =
  "CyberConnect is a decentralized social graph protocol that helps dApps bootstrap network effects and build personalized social experiences.";
const copyright = `© 2021 - ${new Date().getFullYear()} CyberConnect. All rights reserved.`;
const icons = ["github", "twitter"] as const;

const Footer = () => {
  return (
    <Stack>
      <Typography fontSize={17} color="text.primary" mb={2}>
        {caption}
      </Typography>
      <Typography
        fontSize={14}
        color="text.secondary"
        sx={{ width: { xs: "100%", md: "50%" } }}
      >
        {description}
      </Typography>
      <Typography color="text.secondary" mt={8}>
        {copyright}
      </Typography>
    </Stack>
  );
};

export default Footer;
